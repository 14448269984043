export const listVideosUI = /* GraphQL */ `
    query ListVideos(
        $filter: ModelVideoFilterInput
        $limit: Int
        $nextToken: String
    ) {
        listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
            items {
                id
                description
                eventName
                videoName
                dateTime
                videoStatus
                upVotes
                downVotes
                createdAt
                updatedAt
                fileName
                decision
                videoUrl
            }
            nextToken
            startedAt
        }
    }
`;

export const listUserVideoVotesUI = /* GraphQL */ `
    query ListUserVideoVotess(
        $id: ID
        $videoId: ModelStringKeyConditionInput
        $filter: ModelUserVideoVotesFilterInput
        $limit: Int
        $nextToken: String
        $sortDirection: ModelSortDirection
    ) {
        listUserVideoVotess(
            id: $id
            videoId: $videoId
            filter: $filter
            limit: $limit
            nextToken: $nextToken
            sortDirection: $sortDirection
        ) {
            items {
                id
                videoId
                correct
                createdAt
            }
            nextToken
            startedAt
        }
    }
`;
