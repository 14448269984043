/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateUserVideoVotes = /* GraphQL */ `
  subscription OnCreateUserVideoVotes {
    onCreateUserVideoVotes {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserVideoVotes = /* GraphQL */ `
  subscription OnUpdateUserVideoVotes {
    onUpdateUserVideoVotes {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserVideoVotes = /* GraphQL */ `
  subscription OnDeleteUserVideoVotes {
    onDeleteUserVideoVotes {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideoVotes = /* GraphQL */ `
  subscription OnCreateVideoVotes {
    onCreateVideoVotes {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideoVotes = /* GraphQL */ `
  subscription OnUpdateVideoVotes {
    onUpdateVideoVotes {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideoVotes = /* GraphQL */ `
  subscription OnDeleteVideoVotes {
    onDeleteVideoVotes {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVideo = /* GraphQL */ `
  subscription OnCreateVideo {
    onCreateVideo {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateVideo = /* GraphQL */ `
  subscription OnUpdateVideo {
    onUpdateVideo {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteVideo = /* GraphQL */ `
  subscription OnDeleteVideo {
    onDeleteVideo {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
