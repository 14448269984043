<template>
  <Header :username="this.userName"/>
  <h1>{{ this.eventName }} / {{ this.videoName }}</h1>
  <table>
    <tr>
      <td>
  <video controls>
    <source :src="'https://d2xq6cnlgwaajj.cloudfront.net/' + id + '/AppleHLS1/' + name + '.m3u8'" type="application/x-mpegURL">
    <source :src="'https://d2xq6cnlgwaajj.cloudfront.net/' + id + '/small/' + name + '_small.mp4'" type="video/mp4">
  </video>
      </td>
    </tr>
    <tr>
      <td>
  <button class="button" :disabled='this.canVote === false' name="up" v-on:click="vote(this.id, true)"><font-awesome-icon :icon="['fas', 'thumbs-up']" /> ({{ this.upVotes }})</button>
  &nbsp;
  <button class="button" :disabled='this.canVote === false' name="down" v-on:click="vote(this.id, false)"><font-awesome-icon :icon="['fas', 'thumbs-down']" /> ({{ this.downVotes }})</button>
  &nbsp;
  Referee decision: {{ this.decision }}
      </td>
    </tr>
    <tr>
      <td>
  <div>
    {{ this.description }}
  </div>
      </td>
    </tr>
    <tr>
      <td>
  <button class="button"><router-link :to="{ name: 'Home'}">  <font-awesome-icon :icon="['fas', 'arrow-left']" /> </router-link></button>
      </td>
    </tr>
  </table>
</template>

<script>
import { useRoute } from 'vue-router'
import {API} from "aws-amplify";
import {createUserVideoVotes} from "@/graphql/mutations";
import '../assets/rav.css';
import {onUpdateVideoVotesUI} from "@/graphql/customSubscriptions";
import Header from "@/components/Header";

export default {
  components: {
    Header
  },
  data() {
    return {
      id: '',
      name: '',
      videoName: '',
      description: '',
      canVote: false,
      upVotes: 0,
      downVotes: 0,
      decision: ''
    }
  },
  created() {
    const route = useRoute()
    this.id = route.params.id;
    this.videoName = route.params.videoName;
    this.description = route.params.description;
    this.eventName = route.params.eventName;
    this.canVote = route.params.canVote === 'true';
    this.upVotes = parseInt(route.params.upVotes);
    this.downVotes = parseInt(route.params.downVotes);
    this.name = route.params.name.substring(0, route.params.name.indexOf('.'));
    this.userName = route.params.userName;
    this.decision = route.params.decision;
    this.subscribe();
    console.log('data is ' + JSON.stringify(this))
  },
  methods: {
    async vote(videoId, correct) {
      if(correct)
        this.upVotes += 1;
      else
        this.downVotes += 1;
      this.canVote = false;
      // await router.push({ name: 'Home' })
      await API.graphql({
        query: createUserVideoVotes,
        variables: {input: {videoId: this.id, correct}},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    },
    subscribe() {
      API.graphql({query: onUpdateVideoVotesUI})
          .subscribe({
            next: (eventData) => {
              // console.log('Video player votes updated ' + JSON.stringify(eventData));
              const votesData = eventData.value.data.onUpdateVideoVotes.videoID.split('#');
              if(votesData[0] === this.id) {
                if (votesData[1] === 'up') {
                  this.upVotes = eventData.value.data.onUpdateVideoVotes.votes;
                } else {
                  this.downVotes = eventData.value.data.onUpdateVideoVotes.votes;
                }
                if (eventData.value.data.onUpdateVideoVotes.id === this.userName) {
                  this.canVote = false;
                }
              }
              // this.updateVideoVotes(videoId, eventData.value.data.onUpdateVideoVotes.votes, votesData[1] === 'up');
            }
          });
    }
  }
}
</script>

<style>
video {
  object-fit: cover;
}
</style>