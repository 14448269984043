/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_appsync_graphqlEndpoint": "https://pp7rqzjq35d6jf4hij7ywxvyy4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-2dy3jx4tqjfgjlmiub5e67hx4q",
    "aws_cognito_identity_pool_id": "eu-central-1:9c3602f3-c48e-4fa7-891e-597f3e4755b9",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_4LHHw7zgN",
    "aws_user_pools_web_client_id": "2gjp7h3rghdrl9stcr3rt9rl0f",
    "oauth": {},
    "aws_cognito_login_mechanisms": [
        "PREFERRED_USERNAME"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eplaynote-20211104105450-hostingbucket-staging",
    "aws_content_delivery_bucket_region": "eu-central-1",
    "aws_content_delivery_url": "https://dzlwlbau6fgyf.cloudfront.net"
};


export default awsmobile;
