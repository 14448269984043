<template>
  <div class="videoContent">
    <video controls ref="videoPlayer" class="video-js vjs-16-9">

    </video>
  </div>
</template>

<script>
import videojs from 'video.js';

export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      player: null
    }
  },
  mounted() {
    // console.log('Video player mounted: ' + JSON.stringify(this.options));
    // console.log('Cookies are ' + JSON.stringify(document.cookie))
    this.player = videojs(this.$refs.videoPlayer, this.options,
    );
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose()
    }
  }
}
</script>