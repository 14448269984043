/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUserVideoVotes = /* GraphQL */ `
  mutation CreateUserVideoVotes(
    $input: CreateUserVideoVotesInput!
    $condition: ModelUserVideoVotesConditionInput
  ) {
    createUserVideoVotes(input: $input, condition: $condition) {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateUserVideoVotes = /* GraphQL */ `
  mutation UpdateUserVideoVotes(
    $input: UpdateUserVideoVotesInput!
    $condition: ModelUserVideoVotesConditionInput
  ) {
    updateUserVideoVotes(input: $input, condition: $condition) {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserVideoVotes = /* GraphQL */ `
  mutation DeleteUserVideoVotes(
    $input: DeleteUserVideoVotesInput!
    $condition: ModelUserVideoVotesConditionInput
  ) {
    deleteUserVideoVotes(input: $input, condition: $condition) {
      id
      videoId
      correct
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createVideoVotes = /* GraphQL */ `
  mutation CreateVideoVotes(
    $input: CreateVideoVotesInput!
    $condition: ModelVideoVotesConditionInput
  ) {
    createVideoVotes(input: $input, condition: $condition) {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateVideoVotes = /* GraphQL */ `
  mutation UpdateVideoVotes(
    $input: UpdateVideoVotesInput!
    $condition: ModelVideoVotesConditionInput
  ) {
    updateVideoVotes(input: $input, condition: $condition) {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideoVotes = /* GraphQL */ `
  mutation DeleteVideoVotes(
    $input: DeleteVideoVotesInput!
    $condition: ModelVideoVotesConditionInput
  ) {
    deleteVideoVotes(input: $input, condition: $condition) {
      id
      votes
      videoID
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const createVideo = /* GraphQL */ `
  mutation CreateVideo(
    $input: CreateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    createVideo(input: $input, condition: $condition) {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const updateVideo = /* GraphQL */ `
  mutation UpdateVideo(
    $input: UpdateVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    updateVideo(input: $input, condition: $condition) {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
export const deleteVideo = /* GraphQL */ `
  mutation DeleteVideo(
    $input: DeleteVideoInput!
    $condition: ModelVideoConditionInput
  ) {
    deleteVideo(input: $input, condition: $condition) {
      id
      fileName
      description
      eventName
      videoName
      decision
      videoUrl
      timestamp
      dateTime
      videoStatus
      upVotes
      downVotes
      _version
      _deleted
      _lastChangedAt
      createdAt
      updatedAt
    }
  }
`;
