<template>
  <div class="header">
    <table width="100%">
      <tr><td><h1>Replay'n Vote App</h1></td><td aligh="right">{{ username }} <img width="0" src="./1.png"><img width="0" src="./2.png"><img width="1px" src="./3.png"></td></tr>
    </table>
  </div>
</template>

<script>
import '../assets/rav.css';
  export default {
    name: 'Header',
    props: ['username']
  }
</script>