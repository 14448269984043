import { createApp } from 'vue'
import App from './App.vue'
import Amplify from 'aws-amplify';
import aws_exports from './aws-exports';
import router from './router'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faThumbsUp } from '@fortawesome/free-solid-svg-icons'
import { faThumbsDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons'

import {
    applyPolyfills,
    defineCustomElements,
} from '@aws-amplify/ui-components/loader';


Amplify.configure(aws_exports);
applyPolyfills().then(() => {
    defineCustomElements(window);
});

const app = createApp(App);

library.add(faThumbsUp)
library.add(faThumbsDown)
library.add(faArrowLeft)

app.component('font-awesome-icon', FontAwesomeIcon)
app
    .use(router)
    // .use(Vue3VideoPlayer)
    .mount('#app')

