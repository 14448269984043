<template>
  <amplify-authenticator>
    <div id="app">
      <div v-if="authState === 'signedin'">
         <Header :username = "this.user.username"/>
      </div>
      <div v-for="item in videos" :key="item.id">
        <h3>{{ item.eventName }} / {{ item.videoName }}</h3>
        <div class="player">
           <video-player :options="getVideoOptions(item)"/>
        </div>
        <table border="0">
        <tr>
           <td colspan="1" align="left" width="35%">
             <button class="button" :disabled='!canVote(item.id)' name="up" v-on:click="vote(item.id, true)"><font-awesome-icon :icon="['fas', 'thumbs-up']" /> ({{ item.upVotes }})</button>
             &nbsp;
             <button class="button" :disabled='!canVote(item.id)' name="down" v-on:click="vote(item.id, false)"><font-awesome-icon icon="thumbs-down" /> ({{ item.downVotes }})</button>
           </td>
          <td align="left" width="65%">
            &nbsp;Referee decision: {{ item.decision }}
          </td>
        </tr>
        </table>
        <br/>
        {{ item.description }}
        <br/><br/>
      </div>
     </div>
    <amplify-sign-out></amplify-sign-out>
  </amplify-authenticator>
</template>

<script>
import { API } from 'aws-amplify';
import HLSCore from '@cloudgeek/playcore-hls'
import { onAuthUIStateChange } from '@aws-amplify/ui-components'
import { listVideosUI, listUserVideoVotesUI } from '../graphql/customQueries';
import { createUserVideoVotes } from '../graphql/mutations';
import {onDeleteVideo, onUpdateVideo} from "../graphql/subscriptions";
import { onUpdateVideoVotesUI } from '../graphql/customSubscriptions';
import { useCookies } from "vue3-cookies";
import VideoPlayer from "@/components/VideoPlayer.vue";

import 'video.js/dist/video-js.css';
import '../assets/rav.css';
import Header from '../components/Header';

export default {
  name: 'App',
  components: {
    Header,
    VideoPlayer
  },
  setup() {
    const { cookies } = useCookies();
    return { cookies };
  },
  async created() {
    console.log('Created');
    this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
      this.authState = authState;
      this.user = authData;
      if(authState === 'signedin') {
          console.log('Signature is: ' + this.cookies.get("CloudFront-Signature"));
          this.subscribe();
          this.getVideos();
      }
    })
  },
  async beforeCreate() {
    // console.log('Before create');
  },
  // handleAuthStateChange(nextAuthState, data) {
  //   console.log('Next auth state change ' + JSON.stringify(nextAuthState) + ', data = ' + data);
  // },
  data() {
    return {
      user: '',
      authState: null,
      description: '',
      videos: [],
      voted: [],
      open: false,
      HLSCore
    }
  },
  methods: {
    canVote(videoId){
      return !this.voted.includes(videoId);
    },
    async getVideos() {
      // console.log('Load videos')
      const videos = await API.graphql({
        query: listVideosUI,
        variables: {filter: {videoStatus: {eq: 'PROCESSED'}}}
      });
      this.videos = videos.data.listVideos.items.sort(function (a, b) {
        return b.createdAt.localeCompare(a.createdAt);
      });

      const userVotes = await API.graphql({
        query: listUserVideoVotesUI,
        variables: {id: this.user.username, items: ['videoId']}
      });
      userVotes.data.listUserVideoVotess.items.forEach(item => {
        this.voted.push(item.videoId);
      })
    },
    getSource(url) {
      let source = {
        src: url,
        // resolution: '270p',
        withCredentials: true
      };
      // console.log("Return src " + JSON.stringify(source));
      return [source];
    },
    getVideoOptions(video) {
      let result = {
        autoplay: false,
        controls: true,
        withCredentials: true,
        crossOrigin: 'use-credentials',
        html5: {
          hls: {
           withCredentials: true
        //     overrideNative: true
          }
        },
        sources: {
          type: 'application/x-mpegURL',
          src: 'https://media.jyrseige.people.aws.dev/media/' + video.id + '/AppleHLS1/' + video.fileName.substring(0, video.fileName.indexOf('.')) + '.m3u8'
        }
      };
      return result;
    },
    async vote(videoId, correct) {
      for(var i = 0; i < this.videos.length; i++) {
        if(this.videos[i].id === videoId) {
          if(correct)
            this.videos[i].upVotes += 1;
          else
            this.videos[i].downVotes += 1;
          break;
        }
      }
      this.voted.push(videoId);
      await API.graphql({
        query: createUserVideoVotes,
        variables: {input: {videoId: videoId, correct}},
        authMode: 'AMAZON_COGNITO_USER_POOLS'
      });
    },

    async updateVideoVotes(id, votes, correct, userId) {
      console.log('Update video votes for ' + id)
      for(var i = 0; i < this.videos.length; i++) {
        if(this.videos[i].id === id) {
          if(userId === this.user.username) {
            this.voted.push(id);
          }
          if(correct)
            this.videos[i].upVotes = votes;
          else
            this.videos[i].downVotes = votes;
          break;
        }
      }
    },

    subscribe() {
      API.graphql({ query: onUpdateVideo })
          .subscribe({
            next: (eventData) => {
              console.log('Video updated '/* + JSON.stringify(eventData)*/);
              let video = eventData.value.data.onUpdateVideo;
              if(video.videoStatus === 'PROCESSED') {
                if (this.videos.some(item => item.id === video.id)) return; // remove duplications
                this.videos = [video, ...this.videos];
              } else {
                this.deleteVideoIfNeeded(video.id);
              }
            }
          });
      API.graphql({ query: onUpdateVideoVotesUI })
          .subscribe({
            next: (eventData) => {
              // console.log('Video votes updated ' + JSON.stringify(eventData));
              const votesData = eventData.value.data.onUpdateVideoVotes.videoID.split('#');
              let videoId = votesData[0];
              this.updateVideoVotes(videoId,
                  eventData.value.data.onUpdateVideoVotes.votes,
                  votesData[1] === 'up',
                  eventData.value.data.onUpdateVideoVotes.id);
            }
          });
      API.graphql({ query: onDeleteVideo })
          .subscribe({
            next: (eventData) => {
              let video = eventData.value.data.onDeleteVideo;
              console.log("Removed " + JSON.stringify(video))
              this.deleteVideoIfNeeded(video.id);
            }
          });
    },
    deleteVideoIfNeeded(id) {
      for(var i = 0; i < this.videos.length; i++) {
        if(this.videos[i].id === id) {
          this.videos.splice(i, 1);
          break;
        }
      }
    }
  }
}
</script>
